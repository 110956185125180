import { Model, HasMany, Attr, BelongsTo } from "spraypaint";
import { ApplicationRecord } from "./ApplicationRecord";
import { SurveyItem } from "./SurveyItem";
import { SurveySegment } from "./SurveySegment";
import { Order } from "./Order";
import { SurveyService } from "./SurveyService";
import { ShipmentWeightBreakdowns } from "types";
import { getChangedItemQuantity } from "components/pages/BelongingsPage/tools/surveyAtom";

const hasChangedItems = (items: Record<string, number>) => {
  const changedItemsVals = Object.values(items).filter((item) => item !== 0);
  return changedItemsVals.length !== 0;
};
@Model()
export class Survey extends ApplicationRecord {
  static jsonapiType = "surveys";

  @Attr() isComplete!: boolean;
  @Attr() surveyType!: "VIRTUAL" | "ONSITE";
  @Attr() isPrimary!: boolean;
  @Attr() isPendingApproval!: boolean;
  @Attr() estimatedWeight!: number;
  @Attr() estimatedProGearWeight!: number;
  @Attr() estimatedSpouseProGearWeight!: number;
  @Attr() notes?: string;
  @Attr() serviceId!: number;
  @HasMany() segments!: SurveySegment[];
  @HasMany() items!: SurveyItem[];

  get itemsNonProGear() {
    return this.items.filter(
      ({ category }) => category?.toUpperCase() === "HOUSEHOLD_GOODS"
    );
  }

  get itemsGunSafe() {
    return this.items.filter(({ definitionId }) => definitionId === "2248");
  }

  getEstimatedWeightBreakDown(changedItems: Record<string, number>) {
    return this.itemsNonProGear.reduce((breakdown, item) => {
      const changedQuantity = hasChangedItems(changedItems) ? getChangedItemQuantity(
        changedItems,
        item.segment.order.shipmentType,
        item.roomId ?? "",
        item.id
      ) : 0;

      const orderNumber = item.segment.order.number;
      breakdown[orderNumber] = {
        shipmentType: item.segment.order.shipmentType,
        weight: (breakdown[orderNumber]?.weight ?? 0) + item.getTotalWeight(changedQuantity)
      }
      return breakdown;
    }, {} as ShipmentWeightBreakdowns);
  }

  @BelongsTo(Order) order!: Order;
  @BelongsTo(SurveyService) service!: SurveyService;
}
